import { useRouter } from 'next/router'
import { ComponentType, createContext, ReactNode, useContext, useEffect, useState } from 'react'

import useDirectories from '@/utils/useDirectories'
import usePatreonTier from '@/utils/usePatreonTier'

const removeMediavine = () => {
  removeMediavineContainers()
  removeMediavineVariables()
}

const removeMediavineContainers = () =>
  ['#fixed_container_bottom'].forEach((id) => {
    document.querySelectorAll(id).forEach((el) => el.remove())
  })

const removeMediavineVariables = () =>
  ['$adManagementConfig', '$mediavine', 'Fides'].forEach((key) => {
    if (key in window) {
      delete window[key as any]
    }
  })

type AdsContextProps = {
  loadAds?: boolean
}

const AdsContext = createContext<AdsContextProps | undefined>(undefined)

type AdsProviderProps = {
  children: ReactNode
}

export const AdsProvider = ({ children }: AdsProviderProps) => {
  const { query } = useDirectories()
  const patreonTier = usePatreonTier()
  const router = useRouter()
  const [isClient, setIsClient] = useState(false)

  const loadAds = isClient ? !patreonTier || !!query?.ads : undefined

  useEffect(() => {
    if (!isClient) setIsClient(typeof window !== 'undefined')
  }, [])

  // Remove Mediavine ads if user has Patreon tier, unless ads query is present
  useEffect(() => {
    if (!loadAds) removeMediavine()
  }, [loadAds])

  // Remove Mediavine ads on route change, which Mediavine does already but sometimes fails
  useEffect(() => {
    const handleRouteChange = () => removeMediavineContainers()
    router.events.on('routeChangeStart', handleRouteChange)
    return () => router.events.off('routeChangeStart', handleRouteChange)
  }, [router.events])

  return <AdsContext.Provider value={{ loadAds }}>{children}</AdsContext.Provider>
}

export const useLoadAds = () => {
  const context = useContext(AdsContext)
  if (!context) {
    throw new Error('useLoadAds must be used within an AdsProvider')
  }
  return context.loadAds
}

export const withLoadAds =
  <T extends object>(WrappedComponent: ComponentType<T>) =>
  // eslint-disable-next-line react/display-name
  (props: T) => {
    const loadAds = useLoadAds()
    return <WrappedComponent {...props} loadAds={loadAds} />
  }
