// @ts-nocheck

import { store } from '@/store/store'
import { CardType } from '@/types/card'

import { APPEND_COMMANDER, ERROR, REPLACE_COMMANDER, SET_VIEWER_IMAGE } from './types'

type ReplaceCommanderActionType = {
  type: typeof REPLACE_COMMANDER
  payload: string // url
}

type AppendCommanderActionType = {
  type: typeof APPEND_COMMANDER
  payload: string // url
}

export const promoteToCommander = (card: CardType) => {
  if (!card.url && !card.sanitized_wo) return console.warn('Card has no url or santized_wo')
  const dispatch = store.dispatch
  const cards = store.getState().clipboard.loadedCards
  const clipboard = store.getState().clipboard
  const newCommander = cards[card.sanitized_wo || card.url || '']

  if (
    newCommander.background_chooser ||
    newCommander.doctors_companion ||
    newCommander.legal_partner ||
    newCommander.subtypes?.includes('Background') ||
    newCommander.subtypes?.includes('Doctor')
  ) {
    if (clipboard.commanders.length === 2) {
      if (
        clipboard.commanders.some((commander) => cards[commander].background_chooser) &&
        clipboard.commanders.some((commander) => cards[commander].subtypes?.includes('Background'))
      ) {
        // Handle backgrounds
        const oldBackground = clipboard.commanders.find((commander) =>
          cards[commander].subtypes?.includes('Background'),
        )
        const oldBackgroundChooser = clipboard.commanders.find((commander) => cards[commander].background_chooser)
        dispatch({ type: REPLACE_COMMANDER, payload: card.sanitized_wo || card.url || '' })
        if (newCommander.background_chooser) {
          dispatch({ type: APPEND_COMMANDER, payload: oldBackground || '' })
        } else if (newCommander.subtypes?.includes('Background')) {
          dispatch({ type: APPEND_COMMANDER, payload: oldBackgroundChooser || '' })
        }
      } else if (
        clipboard.commanders.some((commander) => cards[commander].doctors_companion) &&
        clipboard.commanders.some((commander) => cards[commander].subtypes?.includes('Doctor'))
      ) {
        // Handle doctors
        const oldDoctor = clipboard.commanders.find((commander) => cards[commander].subtypes?.includes('Doctor'))
        const oldDoctorCompanion = clipboard.commanders.find((commander) => cards[commander].doctors_companion)
        dispatch({ type: REPLACE_COMMANDER, payload: card.sanitized_wo || card.url || '' })
        if (newCommander.doctors_companion) {
          dispatch({ type: APPEND_COMMANDER, payload: oldDoctor || '' })
        } else if (newCommander.subtypes?.includes('Doctor')) {
          dispatch({ type: APPEND_COMMANDER, payload: oldDoctorCompanion || '' })
        }
      } else if (newCommander.partners_with || cards[clipboard.commanders[0]].partners_with) {
        // Replace commander if new or current commander has partner-with
        dispatch({ type: REPLACE_COMMANDER, payload: card.sanitized_wo || card.url || '' })
      } else {
        // Throw error if there are 2 commanders with partner
        return dispatch({ payload: 'Remove a partner', type: ERROR })
      }
    } else if (clipboard.commanders.length === 1) {
      const commander = cards[clipboard.commanders[0]]
      const newCommander = cards[card.sanitized_wo || card.url || '']

      if (
        (commander.partners_with && commander.partners_with === newCommander.name) ||
        (!commander.partners_with && !newCommander.partners_with)
      ) {
        // Append new commander if they partner-with commander, or if neither partners-with
        dispatch({ type: APPEND_COMMANDER, payload: card.sanitized_wo || card.url || '' })
      } else if (
        (commander.doctors_companion && newCommander.subtypes?.includes('Doctor')) ||
        (newCommander.doctors_companion && commander.subtypes?.includes('Doctor'))
      ) {
        dispatch({ type: APPEND_COMMANDER, payload: card.sanitized_wo || card.url || '' })
      } else if (!commander.legal_partner) {
        // Replace commander if not a partner
        dispatch({ type: REPLACE_COMMANDER, payload: card.sanitized_wo || card.url || '' })
      } else if (
        (commander.background_chooser && newCommander.subtypes?.includes('Background')) ||
        (newCommander.background_chooser && commander.subtypes?.includes('Background'))
      ) {
        dispatch({ type: APPEND_COMMANDER, payload: card.sanitized_wo || card.url || '' })
      } else {
        // Replace commander if new commander does not partner-with commander
        dispatch({ type: REPLACE_COMMANDER, payload: card.sanitized_wo || card.url || '' })
      }
    } else {
      // Append new commander if there are no commanders
      dispatch({ type: APPEND_COMMANDER, payload: card.sanitized_wo || card.url || '' })
    }
  } else {
    // Replace commander if new commander is not a partner
    dispatch({ type: REPLACE_COMMANDER, payload: card.sanitized_wo || card.url || '' })
  }

  dispatch({ type: SET_VIEWER_IMAGE, payload: card.image_uris[0] })
}

export type ClipboardCommanderActions = ReplaceCommanderActionType | AppendCommanderActionType
